import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { ReactComponent as Icon } from './assets/owners-first-logo.svg';
import './Navigation.css';
import { useNavigate } from 'react-router-dom';

const Navigation = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const navbarRef = useRef(null);
    const navigate = useNavigate();

    const handleClick = (page) => {
        navigate(`/${page}`);
        setIsNavExpanded(false);  // Collapse navbar on navigation click
    };

    const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            setIsNavExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Navbar bg="light" expand="lg" id="navbar" expanded={isNavExpanded} ref={navbarRef}>
            <Container fluid>
                <Navbar.Brand href="/"><span id="navbarLogo"><Icon /></span></Navbar.Brand>
                <Navbar.Toggle 
                    aria-controls="navbarScroll"
                    onClick={() => setIsNavExpanded(!isNavExpanded)}  // Toggle expansion
                />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        navbarScroll
                    >
                        <Nav.Link onClick={() => handleClick('')}>Home</Nav.Link>
                        <Nav.Link onClick={() => handleClick('services')}>Services</Nav.Link>
                        <Nav.Link onClick={() => handleClick('portfolio')}>Portfolio</Nav.Link>
                        <Nav.Link onClick={() => handleClick('about')}>About us</Nav.Link>
                        <Nav.Link onClick={() => handleClick('forms')}>Forms</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;
