import React, { useEffect } from 'react';
import { TiSpanner } from 'react-icons/ti';
import { FaShieldAlt } from 'react-icons/fa';
import { IoIosPeople } from 'react-icons/io';
import { MdEmojiPeople } from 'react-icons/md';
import { BiBuildingHouse } from 'react-icons/bi';
import { FaHandshake } from 'react-icons/fa';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './Services.css';
import './App.css';
import './Slide.css';
import servicesHero from './assets/servicesBanner.webp';
import melbourneImg from './assets/melbourne.jpg';
import { useNavigate } from 'react-router-dom';

const Services = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Scroll to the top when component mounts
        window.scrollTo(0, 0);
    }, []);

    const servicesCardData = [
        {
            title: "Maintenance",
            description: "We understand arranging maintenance is more than just sending a work order. Understanding what your property requires is utmost in our minds.  This is the reason our managers all visit your properties, so when maintenance is required, they know exactly what and who to send.  We can then arrange repairs and maintenance from arranging quotes where required, issuance of work order, coordination of contractors, and ensuring works are completing in a timely manner, and a high and expected standard.  All of our contractors are checked and monitored for appropriate licenses and insurances, so when they enter your property, you can be fully assured they are professional and highly skilled.",
            icon: <TiSpanner class="pageCardIcon" />,
            text: "We offer end-to-end maintenance management solutions with screened and monitored contractors."
        },
        {
            title: "Manager Involvement",
            description: "At Owners First we don't just manage your owners corporation, we get involved in your property.  Our Service Charter highlights our promise that your manager will visit your property quarterly at an absolute minimum, and in most cases more often.  We do not believe in managing from behind a desk.  The more we know about your property, the better prepared we are to maintain and discuss the property with you.  That is why we're busy changing perceptions of the body corporate industry by making people our priority, through better service, greater accessibility, and a forward-thinking approach.",
            icon: <MdEmojiPeople class="pageCardIcon" />,
            text: "We manage all aspects of the Owners Corporation's financials. Our efficient and reliable approach promotes transparency and accountability."
        },
        {
            title: "Insurance",
            description: "The Owners First team will handle all of your Owners Corporation insurance needs, from obtaining insurance valuations for the property, quoting the insurance through to handling the claims process where required.  All Owners First staff are accredited with, and proudly use the services of Whitbread Insurance Brokers who is one of Australia's largest privately owned insurance brokers.  We arrange quotes every year for your Owners Corporation, ensuring your property always retains the best possible insurance at the best possible cost.",
            icon: <FaShieldAlt class="pageCardIcon" />,
            text: "We manage all Owners Corporation insurance needs, from valuations through to claims, with accredited and leading brokers."
        },
        {
            title: "Meetings and Documentation",
            description: "At Owners First, meeting with the owners is what we are about.  Apart from the standard Annual General Meetings, which we will hold in your area, in a location selected by the owners, we will also be available to meet with committee and owners in-between the 'standard' and required meetings.  As for the documentation side of the Owners Corporations, although this may not be the most exciting aspect, Owners First definitely see this as a most important aspect for you the owner.  Owners can be assured all legislative requirements are met and adhered to, but also, that this information is available to owners.  Not only available, but easy to access.",
            icon: <FaHandshake class="pageCardIcon" />,
            text: "We provide comprehensive meeting and documentation services to ensure effective communication and collaboration."
        },
        {
            title: "Manager Property Visits",
            description: "As part of our promise to owners in Owners First Service Charter, all our managers visit your property four times a year at an absolute minimum.  There is absolutely no charge for these visits, this is a service to you that we believe is essential for our managers to be able to manage your property effectively.  Our site visits will be with owners or committees were possible, however, all owners can be notified of our visits through our various social media and communication practices.",
            icon: <IoIosPeople class="pageCardIcon" />,
            text: "Our managers undertake regular on-site visits to better understand and maintain our client's properties."
        },
        {
            title: "Property Development",
            description: "Owners First has developed a distinctive and constructive developer program, which offers developers a full range of Owners Corporation consultancy with unique and long term benefits for developers.  A designated team dedicated to developer engagement has been set up, not only to meet developer's needs, but so as not to affect our (and your) owner's services standards.  Enquire now and obtain an obligation free coffee on us, to discuss your needs.",
            icon: <BiBuildingHouse class="pageCardIcon" />,
            text: "Our comprehensive property development consultancy service facilitates the short-term goals of developers, and also the long-term needs of the wider community."
        }
    ];

    const bulletCardData = [
        {
            first: "Fulfill obligations under the Owners Corporations Act 2006, Regulations 2007, Rules, and other applicable laws",
            second: "Ensure compliance with relevant regulations and rules",
            third: "Prepare financial statements and maintain records"
        },
        {
            first: "Handle accounting matters for the Owners Corporation",
            second: "Manage records and correspondence for the Owners Corporation",
            third: "Coordinate all Owners Corporation meetings and related documentation"
        },
        {
            first: "Arrangement of insurance, processing of claims, and property valuations",
            second: "Perform maintenance, repairs, and 24-hour emergency service as directed by Owners Corporation",
            third: "Keeping up to date with relevant legislation and regulations"
        }
    ];

    const renderServices = () => {
        return servicesCardData.map(({ title, text, icon }) => {
            return (
                <li>
                    <Card id="pageCardTransparent">
                        <Card.Body id="pageCardTransparentBody">
                            <div class="pageCardIconBackground">{icon}</div>
                            <Card.Title id="pageCardTransparentTitle">{title}</Card.Title>
                            <Card.Text id="pageCardTransparentBodyText">{text}</Card.Text>
                        </Card.Body>
                    </Card>
                </li>
            )
        })
    }

    const renderBullets = () => {
        return bulletCardData.map(({ first, second, third }) => {
            return (
                <div>
                    <li>
                        <Card id="pageCardDarkBullets">
                            <Card.Body id="pageCardTransparentBodyBullets">
                                <Card.Text id="pageCardTransparentBodyText">• {first}</Card.Text>
                                <Card.Text id="pageCardTransparentBodyText">• {second}</Card.Text>
                                <Card.Text id="pageCardTransparentBodyText">• {third}</Card.Text>
                            </Card.Body>
                        </Card>
                    </li>
                </div>
            )
        })
    }

    const handleRequestManagementClick = () => {
        navigate('/proposal');
    }

    return (
        <div>
            <div class="pageHeadingContainer">
                <span class="pageHeading">Discover our services</span>
            </div>
            <div class="pageMainPhotoContainer">
                <img class="pageMainPhoto" src={servicesHero} />
            </div>
            <div class="pageSubHeadingContainer">
                <span class="pageSubHeading">Engaged and transparent</span>
            </div>
            <div class="pageHeadingUnderContainer">
                <span class="pageHeadingUnder">We aim to elevate your property through consistent, high performance service. Experience a more engaged and transparent relationship with your strata manager.</span>
                <br></br>
            </div>
            <Button id="pageButton" onClick={handleRequestManagementClick}>Request management</Button>
            <div class="slideInFromRight">
                <div class="pageCardContainer">
                    <ul class="cardContainer2 wrap">
                        {renderServices()}
                    </ul>
                </div>
            </div>
            <div class="slideInFromBottom">
                <div class="pageSubHeadingContainer">
                    <span class="pageSubHeading">Get the most out of your property</span>
                </div>
                <div class="pageHeadingUnderContainer">
                    <span class="pageHeadingUnder">We provide boots on the ground consultation and communication. With Owners First, you can be confident that your investment is in experienced hands.</span>
                </div>
                <div class="pageCardContainer">
                    <ul class="cardContainer2 wrap">
                        <div class="pageMainPhotoContainer">
                            <img class="pageMainPhoto" src={melbourneImg} />
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Services;